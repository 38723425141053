import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIviZmuICK3F0NrVUM3jaTuz3L8K0FlWY",
  authDomain: "loannotification-796a2.firebaseapp.com",
  projectId: "loannotification-796a2",
  storageBucket: "loannotification-796a2.appspot.com",
  messagingSenderId: "1021316977306",
  appId: "1:1021316977306:web:2206cb29d7fafb053a8523",
  measurementId: "G-Y3W1CBPDBP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
