import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Demo() {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);
    const formData = JSON.parse(localStorage.getItem('details')) || {};
    const mobileNo = localStorage.getItem('MBN') || '';
    const loanAmount = localStorage.getItem('LoanAmount') || '';
    const [emiDetails, setEmiDetails] = useState([]);

    const emiOptions = [
        { months: 12, price: '41,649' },
        { months: 24, price: '21,809' },
        { months: 36, price: '15,215' },
        { months: 48, price: '11,933' },
        { months: 60, price: '9,975' },
        { months: 72, price: '8,680' },
    ];

    const handleClick = (option, company) => {
        const selectedCompany = moreOptionss.find(item => item.title === company);
        
        const selectedDetails = { 
            ...option, 
            company, 
            logo: selectedCompany.logo, 
            rate: selectedCompany.ROI 
        };
    
        localStorage.setItem('selectedEMIDetails', JSON.stringify(selectedDetails));
        setSelectedOption(selectedDetails);
    };

    const handleGetOffer = () => {
        const savedDetails = localStorage.getItem('selectedEMIDetails');
        if (savedDetails) {
            const selectedDetails = JSON.parse(savedDetails);
            console.log(`Selected EMI Option: 
                ${selectedDetails.months} Months, 
                Rs. ${selectedDetails.price}, 
                Company: ${selectedDetails.company}, 
                Rate: ${selectedDetails.rate}, 
                Logo: ${selectedDetails.logo}`);
            navigate('/membershiporder');
        } else {
            console.log("No EMI option selected");
        }
    };

    const moreOptionss = [
        {
            title: 'Money View',
            subTitle: 'Personal Loan',
            loan_amt: loanAmount,
            ROI: '18',
            Tenure: '60',
            emiOptions: [
                { months: 12, price: '₹ 41,649' },
                { months: 24, price: '₹ 21,809' },
                { months: 36, price: '₹ 15,215' },
                { months: 48, price: '₹ 12,174' },
                { months: 60, price: '₹ 10,356' },
                { months: 72, price: '₹ 9,279' },
            ]
        },
        {
            title: 'Upwards',
            subTitle: 'Personal Loan',
            loan_amt: loanAmount,
            ROI: '22',
            Tenure: '24',
            emiOptions: [
                { months: 12, price: '₹ 48,420' },
                { months: 24, price: '₹ 30,400' },
                { months: 36, price: '₹ 23,091' },
                { months: 48, price: '₹ 18,185' },
                { months: 60, price: '₹ 15,223' },
                { months: 72, price: '₹ 13,232' },
            ]
        },
        {
            title: 'faircent.com',
            subTitle: 'Personal Loan',
            loan_amt: loanAmount,
            ROI: '12',
            Tenure: '36',
            emiOptions: [
                { months: 12, price: '₹ 43,139' },
                { months: 24, price: '₹ 22,587' },
                { months: 36, price: '₹ 17,743' },
                { months: 48, price: '₹ 14,451' },
                { months: 60, price: '₹ 12,237' },
                { months: 72, price: '₹ 10,725' },
            ]
        },
        {
            title: 'TATA Capital',
            subTitle: 'Personal Loan',
            loan_amt: loanAmount,
            ROI: '10',
            Tenure: '72',
            emiOptions: [
                { months: 12, price: '₹ 42,350' },
                { months: 24, price: '₹ 22,657' },
                { months: 36, price: '₹ 16,754' },
                { months: 48, price: '₹ 13,764' },
                { months: 60, price: '₹ 11,591' },
                { months: 72, price: '₹ 10,221' },
            ]
        }
    ];

    useEffect(() => {
        const details = moreOptionss.map((data) => {
            const loanAmount = parseFloat(data.loan_amt.replace(/[^0-9.-]+/g, '')); // Convert loan amount to number
            const rate = parseFloat(data.ROI.replace(/[^0-9.-]+/g, '')); // Convert ROI to number
            const tenures = [12, 24, 36, 48, 60, 72]; // Define tenures to calculate EMI for

            const emiOptions = tenures.map(month => {
                // Monthly interest rate
                const monthlyInterestRate = (rate / 12) / 100;

                // EMI calculation
                const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, month)) / (Math.pow(1 + monthlyInterestRate, month) - 1);

                return {
                    months: month,
                    price: `₹${emi.toFixed(2)}`
                };
            });

            // Collect details for rendering
            return {
                company: data.title,
                tenure: data.Tenure,
                subtitle: data.subTitle,
                logo: data.logo,
                loanAmount: `₹${loanAmount.toFixed(2)}`,
                rate: `${rate}%`,
                emiOptions
            };
        });

        setEmiDetails(details);
    }, [moreOptionss]);

    useEffect(() => {
        // Find the Money View option
        const moneyViewOption = emiDetails.find(item => item.company === 'Money View');
        
        if (moneyViewOption && moneyViewOption.emiOptions.length > 0) {
            // Set the default selected option for Money View
            const defaultOption = moneyViewOption.emiOptions[0];
            setSelectedOption({
                ...defaultOption,
                company: moneyViewOption.company,
                logo: moneyViewOption.logo,
                rate: moneyViewOption.rate,
            });
        }
    }, [emiDetails]);

    return (
        <>
            <div className="bg-gray-100 flex items-center justify-center">
                <div className="mx-auto w-full p-8 rounded-lg max-w-6xl">
                    <h1 className="text-3xl font-[500] mb-4 text-gray-800">Personal Loan</h1>
                    <p className='mb-2 text-md text-gray-800'>Congrats, {formData.fullName}! </p>
                    <p className="mb-8 text-md text-gray-800">
                        Your Loan Amount <span className='font-[600] text-green-600'>₹ {Number(loanAmount).toLocaleString('en-IN')}/- </span> is Successfully Pre-Approved. Kindly Proceed With your Loan Offer Now!
                    </p>
                    <div className="">
                        <div className="p-8 border border-orange-400 rounded-lg bg-white shadow col-span-1">
                            <div className='bg-[#f4f6f9] px-6 py-6'>
                                <h2 className="mb-4 font-[500] tracking-widest">Your loan amount is successfully approved...!!</h2>
                                <p className="text-2xl sm:text-3xl font-[400]">₹ {Number(loanAmount).toLocaleString('en-IN')}/-</p>
                            </div>
                            <p className='pt-4 text-md text-gray-800'>Choose your preferred EMI option below.</p>
                            <div className='mt-4'>
                                {emiDetails.map((data, index) => (
                                    <div key={index} className="p-4 border rounded-lg bg-white shadow my-4">
                                        <h3 className="text-xl font-bold">{data.company}</h3>
                                        <p className="text-md">{data.subtitle}</p>
                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            {data.emiOptions.map((option, idx) => (
                                                <div key={idx} className={`p-4 border rounded-lg cursor-pointer ${selectedOption && selectedOption.months === option.months && selectedOption.company === data.company ? 'bg-yellow-300' : 'bg-gray-100'}`} onClick={() => handleClick(option, data.company)}>
                                                    <p className="text-md font-semibold">{option.months} Months</p>
                                                    <p className="text-lg">{option.price}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button onClick={handleGetOffer} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg">Get Offer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
