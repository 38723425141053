import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Componets/Header';
import Sidebar from './Componets/Sidebar';
import Home from './Componets/Home';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Demo from "./Componets/Demo";
import Login from './Componets/Login';
import Payment from './Componets/Category/Payme';
import Gorupee from './Componets/Category/Gorupee';
import App2 from './Componets/Category/APP2';
import App3 from './Componets/Category/App3';
import SelectModePayment from './Componets/Category/SelectModePayment';
import { messaging, getToken, onMessage } from './firebase';

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [fcmToken, setFcmToken] = useState(null);

  const toggleSidebar = () => setIsSidebarOpen(prevOpen => !prevOpen);
  const OpenSidebar = () => setOpenSidebarToggle(!openSidebarToggle);

  useEffect(() => {
    const loggedIn = localStorage.getItem('astro_logged_in') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'BOLOGgf0O1V7YzcmT_IN77XUEjhAI0x6cDPWPkYMXUZTxDSkpRJGvD-Y1BxyDVJnhPTzy7ECF1cTG162iUpe3f0'
        });
        console.log('FCM Token:', token);
        setFcmToken(token);
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    requestPermission();
  }, []);

  function subscribeTokenToTopic(token, topic) {
    fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'BOLOGgf0O1V7YzcmT_IN77XUEjhAI0x6cDPWPkYMXUZTxDSkpRJGvD-Y1BxyDVJnhPTzy7ECF1cTG162iUpe3f0', // Correct the authorization header
        'Content-Type': 'application/json'
      })
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.log('Error subscribing to topic:', response.status, response);
      } else {
        console.log(`"${topic}" is subscribed`);
      }
    })
    .catch((error) => {
      console.error('Error during subscription:', error);
    });
    return true;
  }

  useEffect(() => {
    const sendNotification = () => {
      if (fcmToken) {
        console.log("fcmToken", fcmToken);
        
        const topic = "loanweb";  // Define the topic here

        const data = {
          title: "Scheduled Notification",
          body: "This notification was sent every 2 seconds.",
          token: fcmToken,
          file: 'loannotification',
          icon: "https://i.ibb.co/6m79XMd/favicon1.png",
          image: "https://i.ibb.co/z6bWGqR/banner1.png",
          topic: topic
        };

        subscribeTokenToTopic(fcmToken, topic);  // Use the defined topic
        fetch('https://api.hindustanrides.in/sendLoanotify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => response.text())
        .then(result => {
          console.log('Notification response:', result);
        })
        .catch(error => {
          console.error('Error sending notification:', error);
        });
      } else {
        console.warn('FCM Token is not available.');
      }
    };
  
    // Start sending notifications every 2 seconds
    const intervalId = setInterval(sendNotification, 2000);
  
    // Clear the interval when the component unmounts or the token changes
    return () => clearInterval(intervalId);
  }, [fcmToken]);

  return (
    <>
      <Router>
        {isLoggedIn ? (
          <div className='grid-container'>
            <Header OpenSidebar={OpenSidebar} />
            <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
            <Routes>
              <Route path="/dashboard" element={<Home />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/dashboard/gorupee" element={<Gorupee />} />
              <Route path="/dashboard/app2" element={<App2 />} />
              <Route path="/dashboard/app3" element={<App3 />} />
              <Route path="/paymentmode" element={<SelectModePayment />} />
              <Route path="/demo" element={<Demo />} />
            </Routes>
            <ToastContainer closeButton={false} position="bottom-left" />
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          </Routes>
        )}
        <ToastContainer closeButton={false} position="bottom-left" />
      </Router>
    </>
  );
}

export default App;
